/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import './styles/mixins';
@import './styles/custom_component.scss';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  display: none;
}

code {
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

/* Custom Antd */
.ant-menu-submenu-title {
  font-weight: bold;
  margin: 0;
}

.ant-menu-submenu-selected {
  color: #1953d8;
}

.ant-menu-submenu-selected > .ant-menu-item-selected {
  background-color: white;
}

.ant-breadcrumb a:hover {
  color: #063ec2;
}
