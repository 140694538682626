@import '../../../styles/style.scss';

.forgot-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  .left-col {
    display: none;
    width: 50%;
    background-image: url('../../../assets/images/login-bg.png');
    background-size: 100% 100%;
  }

  .right-col {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .form {
      width: 400px;
      .logo {
        height: 80px;
        width: 80px;
        background-image: url('../../../assets/images/lock-icon.PNG');
        background-size: 100% 100%;
        margin-bottom: 10px;
      }

      .back {
        font-size: 16px;
      }

      .form-tile {
        font-weight: 600;
        font-size: 22px;
        margin-top: 10px;
      }

      .forgot-password {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }

      .submit-btn {
        width: 100%;
        background-color: #1953d8;
        height: 44px;
        border-radius: 7px;
      }

      .confirm-notify {
        .try-btn {
          border: none;
          background-color: transparent;
          color: #1953d8;
          cursor: pointer;
        }
      }
    }
  }
}

// ===============================Reponesive====================================

@include mobile_portrait() {
  .forgot-container {
    .right-col {
      .form {
        width: 90%;
      }
    }
  }
}
