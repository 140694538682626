.custom-input {
  // border-radius: 5px;
}

.custom-select {
  // border-radius: 5px;
  min-width: 150px;
}

.custom-button {
  // border-radius: 5px;
  min-width: 120px;
}

.ant-checkbox-inner,
.ant-tree-checkbox-inner {
  border-radius: 2px;
}

.ant-form-item .ant-input-number-handler-wrap,
.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number {
  width: 100%;
}
