.upload-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .upload-list-inline {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .ant-upload {
        width: 80%;
        .upload-button {
          width: 100%;
        }
      }
    }
    .upload-list {
      width: 80%;
      .file-name {
        max-width: 3rem;
      }
    }
  }
  .form-item-checkbox {
    .ant-form-item {
      margin: 6px 0;
      .ant-checkbox-wrapper {
        font-size: 13px;
        .ant-checkbox {
          padding-top: 6px;
        }
        .text-blue {
          color: #2246dc;
          font-weight: 600;
        }
      }
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 12px;
      color: #d2434d;
    }
  }
  .title-modal {
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
  }
  .total-fee {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
    font-size: 13px;
    .money {
      color: blue;
    }
  }
  .sub-title {
    font-size: 13px;
  }
  .files-support {
    width: 100%;
    font-weight: 400;
    font-size: 11px;
    font-style: italic;
    color: #9cadc4;
    text-align: left;
    padding-left: 10%;
  }
}
.delivery-success-title {
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}
