@import '../../../styles/style.scss';

.change-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  .left-col {
    display: none;
    width: 50%;
    background-size: 100% 100%;
  }

  .right-col {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .form {
      width: 400px;
      .logo {
        height: 80px;
        width: 80px;
        background-image: url('../../../assets/images/key-icon.PNG');
        background-size: 100% 100%;
        margin-bottom: 10px;
      }

      .back {
        font-size: 16px;
      }

      .form-tile {
        font-weight: 600;
        font-size: 22px;
        margin-top: 10px;
      }

      .submit-btn {
        width: 100%;
        background-color: #1953d8;
        height: 44px;
        border-radius: 7px;
      }
    }
  }
}

// ===============================Reponesive====================================

@include mobile_portrait() {
  .change-container {
    .right-col {
      .form {
        width: 90%;
      }
    }
  }
}
